// import React, { useEffect } from 'react';
import React, { useState } from 'react';

const Experiences = () => {
    const [showTooltip, setShowTooltip] = useState(false);

    const copyToClipboard = () => {
        const email = "fabien.mahe85@gmail.com";
        navigator.clipboard.writeText(email)
            .then(() => {
                setShowTooltip(true);  // Show the tooltip when copied
                setTimeout(() => setShowTooltip(false), 2000);  // Hide the tooltip after 2 seconds
            })
            .catch(err => {
                console.error('Failed to copy email: ', err);
            });
    };

    return (
      <div style={Container} id='experiences'>
        <h2>Experiences</h2>
        <div className='jobContainer'>
            <div style={jobs}>
                <div className='job'>
                    <h5><span className='bold'>Senior Product designer</span>, Onfido</h5>
                    <p className='secondary'>Apr 2019 - Now, Lisbon, Portugal</p>
                    <ul>
                        <li>Designing for more complex problematics and bigger scopes</li>
                        <li>Collaborating on product vision and strategy with impact</li>
                    </ul>
                </div>
                <div className='job'>
                    <h5><span className='bold'>Product designer</span>, Onfido</h5>
                    <p className='secondary'>Oct 2016 - Mar 2019, London, United Kingdom</p>
                    <ul>
                        <li>Owning design decisions regarding internal tooling</li>
                        <li>Learned to efficiently collaborate with different function</li>
                        <li>Design and build progressively products through an agile method</li>
                    </ul>
                </div>
            </div>
            <div style={other} className='secondary'>
                <div className='other'>
                    <h5><span className='bold'>Speaker</span>, Web2Day</h5>
                    <p className='secondary'>2019, Nantes, France</p>
                </div>
                <div className='other'>
                    <h5><span className='bold'>Designer Ui/Ux</span>, Talan</h5>
                    <p className='secondary'>Apr 2016 - Aug 2016, Nantes, France</p>
                </div>
                <div className='other'>
                    <h5><span className='bold'>Interaction designer</span>, OneOverZero</h5>
                    <p className='secondary'>Oct 2014 - Jan 2015, Zürich, Switzerland</p>
                </div>
                <div className='other'>
                    <h5><span className='bold'>Design intern</span>, Atelier Iceberg</h5>
                    <p className='secondary'>Oct 2014 - Nov 2014, Nantes, France</p>
                </div>
            </div>
            
        </div>
        <div className='contact'>
            <h5>I am looking for my next adventure!<br />Contact me at <span className='bold'>fabien.mahe85@gmail.com</span></h5>
            <div style={buttonContainer}>
                {showTooltip && <div style={tooltip}>Copied!</div>}
                <button style={button} onClick={copyToClipboard}>Copy email to clipboard</button>
            </div>
            
        </div>
      </div>
    );
  };


  const Container ={
      display: 'flex',
      alignItem: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: 'auto',
      padding: '80px 40px',
      gap: '40px',
  }

  const jobs = {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    flexGrow: '4',
  }

  const other = {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    flexGrow: '1',
  }

  const contact ={
    backgroundColor: '#EAEAF6',
    maxWidth: '960px',
    width: '80%',
    padding: '48px',
    borderRadius: '40px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
  }

  const tooltip ={
    backgroundColor: '#373C48',
    color: 'white',
    position: 'absolute',
    marginTop: '-48px',
    padding: '8px 16px',
    borderRadius: '16px',
  }

  const buttonContainer ={
    overflow: 'visible',
  }

  const button ={
    backgroundColor: 'transparent',
    padding: '16px 24px',
    borderRadius: '16px',
    border: 'solid 1px #373C48'
  }

  export default Experiences;