import React from 'react';
import linkedinSVG from './content/linkedin.svg'
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const NavBar = (props) => {
  const navigate = useNavigate();  // Initialize navigate function

  // Handler functions for navigating to specific routes
  const goToProject_Microtasking = () => navigate('/Project_Microtasking');
  const goToProject2 = () => navigate('/project2');
  const goToProject3 = () => navigate('/project3');

    return (
      <nav className="nav">
        <ul className="nav-links">
          <li><a href="/">Home</a></li>
          <li><a href="/#work">Work</a></li>
          <li><a href="/#experiences">Experiences</a></li>
          <li><a href="http://www.linkedin.com/in/fabienmahe"><img src={linkedinSVG} style={linkedin}/></a></li>
        </ul>
      </nav>
    );
  };

const linkedin ={
  maxWidth: '16px',
  margin: '0px',
}

export default NavBar