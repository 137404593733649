import React, { useEffect, useRef } from 'react';
import Matter from 'matter-js';

const SketchComponent = (props) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const matterContainer = document.querySelector("#toolboxContainer")
    const THICCNESS = 60;

// module aliases
var Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Bodies = Matter.Bodies,
    Composite = Matter.Composite;

// create an engine
var engine = Engine.create();

// create a renderer
var render = Render.create({
    element: matterContainer,
    engine: engine,
    options: {
        width: matterContainer.clientWidth,
        height: matterContainer.clientHeight,
        background: "transparent",
        wireframes: false,
        showAngleIndicator: false,
    }
});

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  };

for (let i = 0; i < 20; i++) {
let polygon = Bodies.polygon(getRandomInt(matterContainer.clientWidth), Math.floor(- (getRandomInt(1000)+300) - i*50), getRandomInt(4) + 3, getRandomInt(matterContainer.clientWidth / 10) + 50, {
    render: {
        fillStyle: "#7B61FF"
    },
    friction: 0.3,
    frictionAir: 0.001,
    restitution: 0.6,
    chamfer: { radius: 16},
});
Composite.add(engine.world, polygon)
}

// create boundaries

var ground = Bodies.rectangle(
    matterContainer.clientWidth/2,
    matterContainer.clientHeight + THICCNESS / 2,
    50000,
    THICCNESS,
    { isStatic: true,
        render: {
        strokeStyle: 'transparent',
    } });

let leftWall = Bodies.rectangle(
    0 - THICCNESS / 2,
    matterContainer.clientHeight / 2,
    THICCNESS,
    matterContainer.clientHeight * 50,
    {isStatic: true,
    render: {
        strokeStyle: 'transparent',
    }}
);

let rightWall = Bodies.rectangle(
    matterContainer.clientWidth + THICCNESS / 2,
    matterContainer.clientHeight / 2,
    THICCNESS,
    matterContainer.clientHeight * 50,
    {isStatic: true, render: {
        strokeStyle: 'transparent',
    }}
);

let topWall = Bodies.trapezoid(
    30,
    -120,
    THICCNESS,
    80,
    10,
    {isStatic: true, render: {
        strokeStyle: 'black',
    }}
)

// add all of the bodies to the world
Composite.add(engine.world, [ground, rightWall, leftWall, topWall]);

// Create MouseConstraint
let mouseConstraint = Matter.MouseConstraint.create(engine, {
    mouse: Matter.Mouse.create(render.canvas),
    constraint: {
      stiffness: 0.8,
      render: {
        visible: true,
      },
    },
  });

  // Add MouseConstraint to the world
  Composite.add(engine.world, mouseConstraint);

  // Remove the mousedown listener from the mouseConstraint
  document.removeEventListener("mousedown", mouseConstraint.mouse.mousedown);



Composite.add(engine.world, mouseConstraint);

// run the renderer
Render.run(render);

// create runner
var runner = Runner.create();

// run the engine
Runner.run(runner, engine);

// Resize when window is changing
function handleResize(matterContainer){
    render.canvas.width = matterContainer.clientWidth;
    render.canvas.height = matterContainer.clientHeight;

    Matter.Body.setPosition(
        ground,
        Matter.Vector.create(
            matterContainer.clientWidth/2,
            matterContainer.clientHeight + THICCNESS / 2
        )
    );

    Matter.Body.setPosition(
        rightWall,
        Matter.Vector.create(
            matterContainer.clientWidth + THICCNESS / 2,
            matterContainer.clientHeight / 2,
        )
    );

}

window.addEventListener("resize", () => handleResize(matterContainer));

  }, []);

  return <div ref={canvasRef} />;
};

export default SketchComponent;
