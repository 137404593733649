import React from 'react';

const Project_Button = ({ color, textColor, copy, link }) => {
    const buttonStyle = {
        padding: '8px 16px',
        margin: '0',
        borderRadius: '80px',
        backgroundColor: color,
        color: textColor,
        border: '0px',
        margin: '0px',
        position: 'absolute',
        bottom: '32px',
        right: '32px'
        }

    return (
    <button style={buttonStyle} onClick={link} backgroundColor="red" className="projecctButtonCopy">{copy}
    </button>
  );
};



export default Project_Button