import React from 'react';
import ProjectIMG from './ProjectIMG';
import DStesting from './content/projectImages/DStesting.png';  // Ensure the path is correct
import DSboard from './content/projectImages/DSboard.png';  // Ensure the path is correct

const Project_DesignSprint = () => {
  return (
    <div className="project">
        <header>
            <h1>Improving a machine learning feedback loop </h1>
            <h3 className="secondary">When a Design sprint comes handy to validate or disaprove assumptions</h3>
        </header>
        <div className='projectContent'>
            <div className='projectIntroduction'>
                <div className='introSection'>
                    <h4>Context</h4>
                    <p>To automate a task with machine learning you need a large amount of data, and usually very specific. The simpler the task the easier it is to automate. Imagine automating a task humans take weeks or month to get good at!<br /><br />
                        One of our team collaborated with our Document experts to accelerate our efforts to automate our verifications. One of the stakeholder thought it would be a good opportunity for a design sprint (yes it was) and ask me if I could facilitate it.</p>
                </div>
                <div className='introSection'>
                    <h4>Problem</h4>
                    <p>It takes time and cost money to label data to train an algorithm. Onfido’s Document experts defined a new way of labelling any type of Fraud at a detailed level. Naturally this problem and this opportunity were quick to be put together… The question was how? How might we use this new labelling process, at scale, for the purpose of automation?</p>
                </div>
            </div>
            <div className="projectContentSection">
                <h4>Design Process</h4>
                <div className='designProcessBlock'>
                    <ProjectIMG source={DSboard} label='The Miro board we used for the design sprint'/>
                    <p>In preparation of this design sprint I extensively researched the process. I got hand on the actual Design sprint book and read plenty of interesting articles, tutorials and “how to”s. I based this sprint on this template made by Jake Knapp and Stéphane Cruchon 👉 <a href='https://miro.com/miroverse/design-sprint-jake-knapp/'>https://miro.com/miroverse/design-sprint-jake-knapp/</a>. I did modify it to include process information like timing and number of votes per person.
As a quick overview here is the process we followed:</p>
                    <ul>
                        <li>Monday → Knowledge sharing and mapping: For this day we had some presentation by stakeholders and people from the sprint to align everyone on knowledge and gather How Might We’s. We discussed about our goal and mapped the current experience.</li>
                        <li>Tuesday → Ideation: Most of the day was about gradually moving from ideas to more detailed concepts. We went from written ideas based on research to wireframes. We used multiple exercises to do so like storyboarding and “Design studio workshop”.</li>
                        <li>Wednesday → Decision: This day was the most challenging as a lot of discussion, debates arises from the exercises we did. It’s the point, we needed to take decisions and focus on a specific solution to test. In the end with a bit more time than planned we succeeded and paved the way for the next day.</li>
                        <li>Thursday → Prototyping: We divided the the team on each component we needed to build. One of the biggest win of this week for me is that we used Framer to create this prototype. Therefore we could integrate actual coded component and logic, which engineers helped with. In the end we created a quick and dirty functioning prototype.</li>
                        <li>Friday → Testing and wrap-up: We could set up 5 interviews with actual users for 30min each. One of us lead the interviews, the others took notes. Once all information have been collected we reviewed the final assumption table to see if our solution works.</li>
                    </ul>
                    <ProjectIMG source={DStesting} label='Screenshot of our Zoom call with a user, testing our interactive prototype'/>
            </div>
            </div>
            <div className="projectContentSection">
            <div className='designProcessBlock'>
                <h4>Solution and impact</h4>
                <p>The result of the design sprint was that we still needed to iterate on our idea. It doesn’t mean that this design sprint was a fail, in the contrary we saved a lot of time and money by validating some assumptions and disproving others.<br /><br />

After this sprint, the team decided to implement some of the improvement tested that had an impact and our users liked. Other ideas are now being tested and delivered. From a Facilitation point of view I learned a lot about how to prepare and facilitate a full remote design sprint.<br /><br />

If you want to know more about my experience and what I learned from preparing and facilitating a remote design sprint, go check the article I wrote about it: <a href='https://medium.com/@fabien.mahe/remote-design-sprint-learnings-from-facilitating-2d6327267385'>https://medium.com/@fabien.mahe/remote-design-sprint-learnings-from-facilitating-2d6327267385</a></p>
            </div>
            </div>
        </div>
    </div>
  );
};

export default Project_DesignSprint;
