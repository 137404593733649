import React from 'react';
import ProjectIMG from './ProjectIMG';
import ProjectVid from './ProjectVid';

// Images and Videos
import GraphDocument from './content/projectImages/fraudVis/GraphDocument.png';
import GraphFraudRings from './content/projectImages/fraudVis/GraphFraudRings.png';
import GraphReuseDocAll from './content/projectImages/fraudVis/GraphReuseDocAll.png';
import GraphReuseDocFocus from './content/projectImages/fraudVis/GraphReuseDocFocus.png';
import EvolutionVideo from './content/projectImages/fraudVis/EvolutionVideo.mp4';


const Project_FraudVis = () => {
  return (
    <div className="project">
        <header>
            <h1>Fraud visualisation</h1>
            <h3 className="secondary">A collection of data visualisation created to help tell the story of fraudsters</h3>
        </header>
        <div className='projectContent'>
            <div className='projectIntroduction'>
                <div className='introSection'>
                    <h4>Context</h4>
                    <p>One of our client send us back a list of fraudulent verifications spanning across multiple months. Onfido’s document experts reviewed those and added some information for each item. In the end we had a refined dataset of fraudulent verifications to study.</p>
                </div>
                <div className='introSection'>
                    <h4>Opportunity</h4>
                    <p>To help reporting on what happened and why it happened, the team asked me to help them create visuals for a presentation. More than simple visual I helped them explore the data in a way they never did through Network diagrams.</p>
                </div>
            </div>
            <div className="projectContentSection">
                <div className="designProcessBlock">
                    <h4>How does fraudsters attack Onfido’s system over time?</h4>
                    <p>Using a tool called Gephi, I created some networks diagram were each dot is a verification and each anchor (on the circle) is a specific fraud vector (a common component of a fraud). Each cluster is a different method, usually defined by the identity document chosen to fraud. Playing with colors we can tell different stories:</p>
                    <ul>
                        <li>On the left: The color shows that the verification has the same Document Type (for example: French Identity Card 2023)</li>
                        <li>On the right: The color shows that a verification has been created by a specific person or group of person called Fraud rings</li>
                    </ul>
                    <div className="projectGallery">
                        <ProjectIMG source={GraphDocument} label='Visualisation showing clusters of methodology coloured by Document type'  />
                        <ProjectIMG source={GraphFraudRings} label='Visualisation showing clusters of methodology coloured by Fraud rings'  />
                    </div>
                    <p>One of the most interesting story we could tell was about the evolution of fraud across time: How does methods evolve? (Quick insight: the cluster top right is deepfakes 🎭)</p>
                    <ProjectVid source={EvolutionVideo} label='A video showing the evolution of fraud across time for the dataset received.'  isIframe={false} />
                </div>

                <div className="designProcessBlock">
                    <h4>Was it only this customer?</h4>
                    <p>While talking with the data analyst assigned to this project, we thought about checking if we could find the same frauds or fraudsters in other client... The result speaks by itself, each dot here is a document that has been reused, its size tells how many time, and every external section is a customer:</p>
                    <div className="projectGallery">
                        <ProjectIMG source={GraphReuseDocAll} label='A visual showing all documents that have been reused from the dataset received'  />
                        <ProjectIMG source={GraphReuseDocFocus} label='A visual showing how one document is connected to multiple companies'  />
                    </div>
                </div>
            </div>
            <div className="projectContentSection">
            <h4>Impact</h4>
            <ul>
                <li>Some findings influenced priorities on features and capabilities being developed. Showing the importance of combatting certain type fraud like reusing the same document across the same or different customers.</li>
                <li>The report was shared with customers through a Customer Advisory Board. They responded very positively to it has it showed our reactivity and care to combat fraud.</li>
                <li>Onfido installed a Fraud Lab in their office and some of this is displayed along with other fun stuff! You can see some of this work on the BBC youtube channel has they did a quick segment on the Fraud lab: <a href='https://www.youtube.com/watch?v=h5X6DTTPqXc'>https://www.youtube.com/watch?v=h5X6DTTPqXc</a> (easy timestamp to see exactly 👉 <a href='https://youtu.be/h5X6DTTPqXc?feature=shared&t=227'>here</a> )</li>
            </ul>
            </div>
        </div>
    </div>
  );
};



export default Project_FraudVis;
