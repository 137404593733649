import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, ScrollRestoration } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
 // Import project pages
import Project_Microtasking from './Project_Microtasking'; 
import Project_DesignSprint from './Project_DesignSprint';
import Project_FraudVis from './Project_FraudVis'; 
import Project_QES from './Project_QES';

import Intro from './Intro';
import ProjectGrid from './ProjectGrid';
import NavBar from './NavBar';
import Experiences from './Experiences';

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop to reset scroll position */}
      <div className="App">
        <NavBar />
        <div style={contentContainer}>
          <Routes>
          {/* Define Routes */}
            <Route path="/" element={
              <>
                <Intro />
                <ProjectGrid nativeId="work"/>
                <Experiences />
              </>
            }/>

            <Route path="/Project_Microtasking" element={
              <Project_Microtasking />
            } />
            <Route path="/Project_DesignSprint" element={
              <Project_DesignSprint />
              } />
            <Route path="/Project_FraudVis" element={<Project_FraudVis />} />
            <Route path="/Project_QES" element={<Project_QES />} />            
          </Routes>
        </div>
        
      </div>
    </Router>
  );
}

const contentContainer = {
  // padding: '80px 80px',
  backgroundColor: '#F9F8F6',
  height: '100vh',
}

export default App;
