import React from 'react';
import ProjectIMG from './ProjectIMG';
import ProjectVid from './ProjectVid';

// Project images
import AccessibilitySlide1 from './content/projectImages/QES/AccessibilitySlide1.png'; 
import AccessibilitySlide2 from './content/projectImages/QES/AccessibilitySlide2.png'; 
import AccessibilitySlide3 from './content/projectImages/QES/AccessibilitySlide3.png'; 
import AccessibilitySlide4 from './content/projectImages/QES/AccessibilitySlide4.png';
import AccessibilityChecklist from './content/projectImages/QES/Accessiblity checklist.png'; 
import AccessibilityVideo from './content/projectImages/QES/AccessibilityVideo.mp4'; 
import QESfinal from './content/projectImages/QES/QESfinal.mp4'; 
import OTPFlow from './content/projectImages/QES/OTPFlow.png'; 
import concepts from './content/projectImages/QES/QESconcepts.png'; 
import QEStechnicalConcept from './content/projectImages/QES/QEStechnicalConcept.png'; 

import DStesting from './content/projectImages/DStesting.png';  // Ensure the path is correct

const Project_QES = () => {
  return (
    <div className="project">
        <header>
            <h1>Adapting Onfido's product to new regulations</h1>
            <h3 className="secondary">From getting a certification to a successful product launch.</h3>
        </header>
        <div className='projectContent'>
            <div className='projectIntroduction'>
                <div className='introSection'>
                    <h4>Context</h4>
                    <p>Onfido customers within the Financial sector are subject to regulation regarding Anti Money Laundering and Counter-terrorism Financing. In early 2024, regulators put in place a new standard for European countries to follow. Onfido now has to provide a set of verification compliant with this new regulation.<br /><br />
                    To know more about this regulation I invite you to read this article: <a href='https://onfido.com/blog/history-and-significance-of-eidas-regulation/'>https://onfido.com/blog/history-and-significance-of-eidas-regulation/</a>. Or if you want to read the relevant part of the regulation you can check it here: <a href='https://eur-lex.europa.eu/eli/reg/2024/1183/oj'>https://eur-lex.europa.eu/eli/reg/2024/1183/oj</a></p>
                </div>
                <div className='introSection'>
                    <h4>Problem</h4>
                    <p>Although the regulation is for the whole of European Union, this project was focused on the french market with possible expansions. France decided to create a standard called “PVID” to be given to companies following a specific process. This standard suggest an extremely long and tedious process. Onfido took this opportunity to create another process that comply to the regulation including a Qualified Electronic Signature verification. That gave customer a choice for a better user experience.</p>
                </div>
            </div>

            <div className="projectContentSection">
                <h4>Design Process</h4>
                <div className="designProcessSummary">
                    <div className='dpItem'>
                        <p className='bold'>1. Discover</p>
                        <ul>
                            <li>Provider assessment</li>
                            <li>Certification</li>
                            <li>Accessibility testing</li>
                        </ul>
                    </div>
                    <div className='dpItem'>
                        <p className='bold'>2. Define</p>
                        <ul>
                            <li>Pairing sessions</li>
                            <li>Prioritisation workshop</li>
                        </ul>
                    </div>
                    <div className='dpItem'>
                        <p className='bold'>3. Design</p>
                        <ul>
                            <li>Figma</li>
                            <li>Design critique</li>
                            <li>Collaboration with front-end</li>
                        </ul>
                    </div>
                    <div className='dpItem'>
                        <p className='bold'>4. Test & Deliver</p>
                        <ul>
                            <li>Figma prototype</li>
                            <li>Remote testing</li>
                            <li>Iteration</li>
                        </ul>
                    </div>
                </div>

                <div className='designProcessBlock'>
                    <p className='bold'>1. Getting a certification by creating a Proof of Concept</p>
                    <div className='projectGallery' style={firstBlock}>
                        <ProjectVid source={AccessibilityVideo} label='Video of the user experience using a screen reader for accessibility assessment purposes' isIframe={false} className="visualProjectGallery"/>
                        <div className='designProcessBlock'>
                            <p>In order to launch a Qualified Electronic Signature verification you need to certify your solution. Once we found a provider that could certify a signature as qualified, we quickly used their SDK to create a proof of concept.</p>
                            <p>Even though the idea was to get a quick proof of concept going, the biggest challenge I could see straight away was accessibility. As this is a big part of Onfido’s Design values for customers, I reviewed this version against W3C guidelines. The result was, as expected, a lack of accessibility and a recommendation to build our own interface to better control the quality of our product.</p>
                            <div className="projectGallery">
                                <ProjectIMG source={AccessibilitySlide1} label='Slide taken from the accessibility report'/>
                                <ProjectIMG source={AccessibilitySlide4} label='Slide taken from the accessibility report' />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='designProcessBlock'>
                    <p className='bold'>2. Creating a Minimum Viable Product to start talking to customer</p>
                    <p>With the initiative of creating our own interface, the understanding of how the system would work technically was a priority. As well as the legal aspect of signing a document consider a contract. These, with some UX challenges created a challenging and complex requirement list. </p>
                    <p>Thanks to collaboration with Product and Engineering, I could suggest 3 option to go forward with pros and cons on each.</p>
                    <div className="projectGallery">
                        <ProjectIMG source={QEStechnicalConcept} label='Summary of the technical understanding I needed to go through my design process'/>
                        <ProjectIMG source={concepts} label='3 concepts presented and discussed as a team to find the right direction technically and design-wise'/>
                    </div>
                    <p>After gathering my team’s opinion on the path to take, I turned to my design coworkers to have a different point of view. Following both groups advice and requirements I created an end-to-end prototype that we internally tested. The prototype also has been used to out together this video:</p>
                    <ProjectVid source='https://www.youtube.com/embed/_lglK-xavpw?si=yN_UBgmfWOrw9ou-' label='Screenshot of our Zoom call with a user, testing our interactive prototype' isIframe={true} />
                </div>

                <div className='designProcessBlock'>
                    <p className='bold'>3. Iterating on UX issues</p>
                    <div className='projectGallery' style={firstBlock}>
                        <ProjectVid source={QESfinal} label='Video of the final user experience for the Qualified Electronic Signature' isIframe={false} className="visualProjectGallery"/>
                        <div className='designProcessBlock'>
                            <p><span className='bold'>Understandability issue</span> Users struggle to understand what was happening on the task due to heavy and confusing legal jargon. This came from the text we had to incorporate in our screens to gather consent from users. I lead the initiative to find a compromise between both legal teams to drive a more human copy. </p>
                            <p><span className='bold'>Links and PDFs behaviour:</span> The presence of a PDFs to display on a mobile through different platform (web, android and iOS) created quite the variety of user experience. Each platform have different constraints and requirement, the only similarity was PDF accessibility problem. The solution was quite simple… Don’t use PDF if you can! We can have the same information via text from our provider while the Document to sign has to be a PDF.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="projectContentSection">
            <div className='designProcessBlock'>
                <h4>Solution and impact</h4>
                <p>While the last improvement are being implement, a first release of our product in Beta happened. After a couple of months of buying processes and technical validation the launch of the product was considered successful:</p>
                </div>
                <div className='impactBlock'>
                    <div className-="impactItem">
                        <h4>📈 6 live customers</h4>
                        <p><span className='bold'>→ 2 new customers</span> started using Onfido specifically for this product to become compliant while 4 switch part of their traffic and are ramping up.</p>
                    </div>
                    <div className="impactItem">
                        <h4>🎯 50k verifications</h4>
                        <p>→ After 6 months of General availability we almost achieved 50k verification total which exceeded our forecast!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

const firstBlock ={

}

export default Project_QES;
