import React from 'react';
import ProjectIMG from './ProjectIMG';
import ProjectVid from './ProjectVid';

// Import images
import experienceMap from './content/projectImages/microtasking/experienceMap.png';
import workshopIMG from './content/projectImages/microtasking/workshop.png';
import wireframeIMG from './content/projectImages/microtasking/wireframe.png';
import testingIMG from './content/projectImages/microtasking/testing.png';
import dataIMG from './content/projectImages/microtasking/data.png';
import fraudIMG from './content/projectImages/microtasking/fraudAssessmentFinal.png';
import extIMG from './content/projectImages/microtasking/extractionFinal.png';
import extVid from './content/projectImages/microtasking/extractionPrototype.mov';
import fraudVid from './content/projectImages/microtasking/fraudPrototype.mov';

const Project_Microtasking = () => {
  return (
    <div className="project">
        <header>
            <h1 className="fade-in">Redesigning the specialist tooling at Onfido</h1>
            <h3 className="secondary">A story of how we reduced the time to verify an identity document from 5 to 1 minute</h3>
        </header>
        <div className='projectContent'>
            <div className='projectIntroduction'>
                <div className='introSection'>
                    <h4>Context</h4>
                    <p>Onfido is a company offering digital identity verification all over the world. They use a mix of automated and manual processes to verify one’s identity. To improve their competitiveness and market share they decided to reduce their processing time from 5 to 1 minutes on average.</p>
                </div>
                <div className='introSection'>
                    <h4>Problem</h4>
                    <p>The time required for a user to fully verify an identity document was 117 seconds on average. In order to reduce our overall processing time to 60sec, we had the objective to drop our manual time to less than 75 sec.</p>
                </div>
            </div>
            
            <div className="projectContentSection">
                <h4>Design Process</h4>
                <div className='designProcessSummary'>
                    <div className='dpItem'>
                        <p className='bold'>1. Discover</p>
                        <ul>
                            <li>Experience map</li>
                            <li>Observation / Shadowing</li>
                            <li>Data analysis</li>
                        </ul>
                    </div>
                    <div className='dpItem'>
                        <p className='bold'>2. Define</p>
                        <ul>
                            <li>How Might We</li>
                            <li>Assumptions</li>
                        </ul>
                    </div>
                    <div className='dpItem'>
                        <p className='bold'>3. Design</p>
                        <ul>
                            <li>Pairing session</li>
                            <li>Wireframes</li>
                        </ul>
                    </div>
                    <div className='dpItem'>
                        <p className='bold'>4. Test & Deliver</p>
                        <ul>
                            <li>Framer prototype</li>
                            <li>Coded prototype</li>
                            <li>In-person Testing</li>
                        </ul>
                    </div>
                </div>

                <div className='designProcessBlock'>
                    <div className="projectGallery">
                        <ProjectIMG source={experienceMap} label='An experience map created from observation highlighting problems and opportunities' />
                        <p>We started with a combination of Observation and data analysis to gather as much knowledge as possible about our users. Shadowing enabled us to understand their flow and define areas of improvement. A dive into existing quantitative data shed some lights about why our users had certain behaviour. Thanks to those findings we generated How Might Wes to formulate our design approach:</p>
                    </div>
                    
                    <p>At this point we already had some improvements and ideas from previous workshops. A couple of pairing sessions with the team made easy to define 3 paths to explore based. </p>
                    <div className="projectGallery">
                        <ProjectIMG source={workshopIMG} label='Result of a Design Studio workshop followed by an effort vs impact prioritisation matrix' />
                        <ProjectIMG source={wireframeIMG} label='Screenshot of a wireframing board' />
                    </div>
                    <p>One prototype would be dedicated to figure out the best way to approach parallelising our process. For this one we needed the help of a Front-End engineer to create a credible tool. The other 2 were Framer prototype I created to explore the reduction of time by better knowledge accessibility. Behind this 2 prototypes were our combine vision of an ideal tooling:</p>
                    <div className="projectGallery">
                    <ProjectVid source={fraudVid} label='Video of the Framer prototype for the visual task' isIframe={false} />
                        <ProjectVid source={extVid} label='Video of the Framer prototype for the extraction task' isIframe={false} />
                    </div>
                    <p>We had the opportunity to go to our users and test in-person 3 prototypes. We gathered a large amount of qualitative and quantitative data. We did over 20h of testing with 20 different users. That accumulated to more than 550 tasks processed! A super experience I shared with Lizzie and Ben, respectively UX research and fellow designer.</p>
                    <div className="projectGallery">
                        <ProjectIMG source={testingIMG} label='A photo taken when testing in-person with my colleague' />
                        <ProjectIMG source={dataIMG} label='A screenshot of quantitative data gathered through our prototypes visualised in Tableau' />
                    </div>
                </div>
            </div>
            <div className="projectContentSection">
                <h4>Solution and impact</h4>
                <div className='designProcessBlock'>
                    <p>Thanks to all the data collected onsite we could easily validate that parallelising our process was a good solution to reduce time and achieved a median of 59 second. The qualitative data gathered also indicated low-hanging fruit improvement we could focus on.</p>
                    <div className="projectGallery">
                        <ProjectIMG source={fraudIMG} label='The final design delivered for the visual assessment task' />
                        <ProjectIMG source={extIMG} label='The final design delivered for the extraction task' />
                    </div>
                </div>
                <ul>
                    <li>Beta-testing: We created an Minimum Viable Product with existing designs, only for a subset of the traffic. This saw a reduction of 40% in time taken to verify identities, validating our product with live traffic.</li>
                    <li>Expansion and fine-tuning: Releasing this new process on more live traffic actually had a positive impact on automation, thanks to the </li>
                    <li>Iteration: After the first full-release we continued to improve this tool with the features enabling better accessibility to knowledge required to complete the task.</li>
                </ul>
                <div className='impactBlock'>
                    <div  className="impactItem">
                        <h4>⏱️ 67 seconds</h4>
                        <p><span className='bold'>→ Reduce the time by 43%</span> on average which allowed Onfido to improve their service offering.</p>
                    </div>
                    <div  className="impactItem">
                        <h4>🕹️ Testing process</h4>
                        <p>→ Onfido decided to <span className='bold'>hire a specific UX researcher</span><br />
                        → Engineering team decided to <span className='bold'>put in place a “ghost mode” version of our system.</span></p>
                    </div>
                    <div  className="impactItem">
                        <h4>🧠 Unforseen value</h4>
                        <p><span className='bold'>→ Skill-based assignment capabilities</span> allowed to serve tasks with different level of knowledge to the appropriate users.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Project_Microtasking;
