import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation(); // Get current route

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to top on route change
  }, [pathname]); // Trigger this effect when route (pathname) changes

  return null;
};

export default ScrollToTop;