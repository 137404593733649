import React, { useRef, useEffect, useState } from 'react';


const ProjectVid = ({ source, label, isIframe }) => {
    const imageRef = useRef(null); // Reference for the image
    const [imageWidth, setImageWidth] = useState(null); // State for image width

    useEffect(() => {
        // Function to update the label width based on image width
        const updateImageWidth = () => {
            if (imageRef.current) {
                setImageWidth(imageRef.current.offsetWidth); // Set the image's width
            }
        };

        // Update width after the image has loaded
        if (imageRef.current && imageRef.current.complete) {
            updateImageWidth();
        } else {
            imageRef.current.addEventListener('load', updateImageWidth); // Ensure the width is updated when the image loads
        }

        // Handle window resizing
        window.addEventListener('resize', updateImageWidth);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', updateImageWidth);
            if (imageRef.current) {
                imageRef.current.removeEventListener('load', updateImageWidth);
            }
        };
    }, []);
    
    return (
    <div style={container}>
        {/* <img src={source} alt={label} style={image}/> */}
        {/* <video style={image} controls>
            <source src={source} type="video/mp4"></source>
        </video> */}
        {isIframe ? (
        // Render iframe when isIframe is true
        <iframe
          src={source}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={label}
          style={videoStyle}
          ref={imageRef}
          height='300px'
          width='100%'
        ></iframe>
      ) : (
        // Render video tag when isIframe is false
        <video style={videoStyle} controls ref={imageRef}>
          <source src={source} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
        <p className='imageLabel'>{label}</p>
    </div>
  );
};

const container = {
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    justifyContent: 'flex-start', 
    overflow: 'hidden', // Prevents the image from overflowing the container
};

const videoStyle = {
    alignSelf: 'flex-start',
    width: '99%',
    maxHeight: '400px',
    borderRadius: '8px',
    border: 'solid 1px rgb(104, 110, 120, 0.5)',
    objectFit: 'contain',
};

export default ProjectVid;