import React from 'react';
// import ProjectMenuItem from './ProjectMenuItem';
import FraudVisualisation from './content/TestimageVisualisations.png'; // Import the img
import FraudAssessment from './content/FraudAssessment.png';
import QESscreen from './content/QEScreen.png';
import DesignSprint from './content/DesignSprint.png';
import Project_Button from './Project_Button';
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const ProjectGrid = () => {
    const navigate = useNavigate();  // Initialize navigate function

  // Handler functions for navigating to specific routes
    const goToMicrotasking = () => navigate('/Project_Microtasking');
    const goToFrauVis = () => navigate('/Project_FraudVis');
    const goToQES = () => navigate('/Project_QES');
    const goToDesingSprint = () => navigate('/Project_DesignSprint');
    

    return (
      <div id='work'>
        <div style={microtasking} className='projectLarge'>
            <div style={projectContent}>
                <div style={microtaskingContent}>
                    <h4>Redesigning the specialist's tooling at Onfido</h4>
                    <p className='secondary'>A story of how we reduced the time to verify an identity at Onfido from 5 minutes to 1. </p>
                </div> 
            </div>
            <img src={FraudAssessment} style={microIMG}/>
            <Project_Button
                    color='rgb(61,61,61,0.5)'
                    textColor='white'
                    copy='see project'
                    link={goToMicrotasking}
                    />
        </div>
        <div style={fraudVisualisations} className='projectLarge'>
            <div style={FVContent}>
                <h4>Fraud visualisations</h4>
                <p>A couple of exploration to help visually tell a story: How fraudsters successfully go through Onfido’s security measures?</p>
            </div>
            <img src={FraudVisualisation} style={fraudImg}/>
            <Project_Button
                    color='rgb(189,195,255,0.5)'
                    textColor='white'
                    copy='see project'
                    link={goToFrauVis}/>
        </div>
        <div style={compliance} className='projectLarge'>
            <div style={projectContent}>
                <h4>Adapting Onfido’s product to new regulations</h4>
                <p>From getting a certification to a successful product launch.</p>
            </div>
            <Project_Button
                    color='rgb(61,61,61,0.5)'
                    textColor='white'
                    copy='see project'
                    link={goToQES}/>
            <img src={QESscreen} style={complianceIMG}/>
            
        </div>
        <div style={designSprintLarge} className='projectLarge'>
            <h4>Improving a machine learning feedback loop</h4>
            <img src={DesignSprint} style={sprintIMG}/>
            <Project_Button
                    color='rgb(253,167,62,0.5)'
                    textColor='#373C48'
                    copy='see project'
                    link={goToDesingSprint}/>
        </div>
      </div>
    );
  };

//   const projectPageContainer = {
//     display: 'flex',
//     flexWrap: 'wrap',
//     gap: '20px',  // Define the gap between grid items
//     justifyContent: 'flex-start',
//     padding: '40px',
//     alignItems: 'flex-start',
//   };

const projectContent={
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    position: 'relative',
    height: '100%',
}

const microtasking = {
    backgroundColor: '#EAEAF6',
    boxSizing: 'border-box',       // Include padding and border in width calculation
    position: 'relative',
  };
  
  const fraudVisualisations = {
    backgroundColor: '#2B2D32',
    borderRadius: '24px',
    overflow: 'hidden',
    boxSizing: 'border-box',        // Ensure consistent sizing with padding
    gap: '40px',
    position: 'relative',
    padding: '32px',
  };

const microIMG = {
    padding: '24px 24px 24px 0px',
    borderRadius: '16px',
    height: '80%', // Maintain aspect ratio
    width: 'auto',  // Image takes full width of its container
    objectFit: 'cover',
    alignSelf: 'center',
};

const microtaskingContent = {
    flexBasis: '70%',  // Set text content to 60% width
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
};

const FVContent ={
    color: '#fff',
    position: 'relative'
}

const fraudImg={
    position: 'relative',
    width: 'auto', 
    height: '90%',
    alignSelf: 'center',
}

const compliance = {
    backgroundColor: '#BDC3FF',
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    padding: '32px',
 }

const complianceIMG={
    borderRadius: '32px',
    border: 'solid 10px #373C48',
    // position: 'relative',
    margin: '60px 60px 0px 0px',
    height: '100%', // Let the height be determined by the width to maintain aspect ratio
    width: 'auto',  // Image's width will adjust based on the container's size
    objectFit: 'contain',
 }

 const designSprintLarge={
    backgroundColor: '#EAEAF6',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',       // Center items vertically
    justifyContent: 'center',   // Center items horizontally
    flexDirection: 'column',
    textAlign: 'center',
    padding: '48px',
    gap: '48px',
    boxSizing: 'border-box',
 }

 const sprintIMG ={
    width: '90%',
    minWidth: '400px',
    marginBottom: '-80px',
    border: 'solid 10px #373C48',
    borderRadius: '24px',
 }

// const projectcontainer ={
//     display: 'flex',
//     alignItem: 'stretch',
//     gap: '80px',
// }

export default ProjectGrid;