import React, { useRef, useEffect, useState } from 'react';

const ProjectIMG = ({ source, label }) => {
    const imageRef = useRef(null); // Reference for the image
    const [imageWidth, setImageWidth] = useState(null); // State for image width

    useEffect(() => {
        // Function to update the label width based on image width
        const updateImageWidth = () => {
            if (imageRef.current) {
                setImageWidth(imageRef.current.offsetWidth); // Set the image's width
            }
        };

        // Update width after the image has loaded
        if (imageRef.current && imageRef.current.complete) {
            updateImageWidth();
        } else {
            imageRef.current.addEventListener('load', updateImageWidth); // Ensure the width is updated when the image loads
        }

        // Handle window resizing
        window.addEventListener('resize', updateImageWidth);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', updateImageWidth);
            if (imageRef.current) {
                imageRef.current.removeEventListener('load', updateImageWidth);
            }
        };
    }, []);


    return (
    <div style={container}>
        <img ref={imageRef} src={source} alt={label} style={image} />
        <p className="imageLabel" style={{ width: imageWidth ? `${imageWidth}px` : 'auto' }}>{label}</p> {/* Set label width dynamically */}
    </div>
  );
};

const container = {
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    justifyContent: 'flex-start',
    overflow: 'hidden', // Prevents the image from overflowing the container
};

const image = {
    alignSelf: 'flex-start',
    width: 'auto',
    maxWidth: '99%',
    maxHeight: '300px',
    borderRadius: '8px',
    border: 'solid 1px rgb(104, 110, 120, 0.5)',
    objectFit: 'contain',
};

export default ProjectIMG;