import React, { useEffect } from 'react';
import SketchComponent from './SketchComponent';

const Intro = () => {
    useEffect(() => {
        const firstLineElement = document.getElementById('firstLine');
        const secondLineElement = document.getElementById('secondLine');
        firstLineElement.addEventListener('animationend', () => {
          // Set the final state after the animation ends
          firstLineElement.style.opacity = '1';
        });
        secondLineElement.addEventListener('animationend', () => {
            // Set the final state after the animation ends
            secondLineElement.style.opacity = '1';
          });
      }, []);

    return (
      <div className='introContainer'>
        <div  style={helloContainer} className="fade-in">
            <div style={helloText}>
                <h1 id="firstLine" className="fade-in" style={firstLine}>👋<br />I’m Fabien Mahé,<br />Senior Product Designer @Onfido.</h1>
                <h3 id="secondLine" className="fade-in" style={secondLine}>Focused on solving problems through a flexible design process. I also like storytelling through data visualisation and playing around with p5.js.</h3>
            </div>
        </div>
        {/* <div style={toolboxContainer} id="toolboxContainer">
            <SketchComponent windowWidth="400" windowHeight="400"/>
        </div> */}
      </div>
    );
  };


  const introContainer ={
      display: 'flex',
      alignItem: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'row',
      marginBottom: '160px',
      padding: '80px',
  }

  const helloContainer ={
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}

  const helloText = {
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      textAlign: 'left',
      order: '1',
      marginTop: '10%',
      width: '100%',
      flewGrowth: '2',
  }

  const firstLine = {
    opacity: '0',
    animation: 'fadeIn 1s ease-in-out 0.3s',
  }

  const secondLine = {
    opacity: '0',
    animation: 'fadeIn 1s ease-in-out 0.6s',
    color: '#686E78',
  }

//   const toolboxContainer = {
//     backgroundColor: 'white',
//     width: '38.2%',
//     order: '2',
//     margin: '80px 80px 80px 0px',
//     borderRadius: '16px',
//     maxHeight: 'calc(100vh - 160px)',
//     overflow: 'hidden',
// }

  export default Intro;